import React from 'react';
import './navbar.css';


const ICON_WIDTH = 15;

class Navbar extends React.Component {
	constructor(props) {
    super(props);
    this.state = {
      isActive: false,
    };
  }

  toggleClassactive = () => {
    this.setState((prevState) => ({
      isActive: !prevState.isActive,
    }));
  };
  render() {
    return (
	<header class="navbar-logo-left-copy">
     <div class="navbar-wrapper">
		  <a href='https://www.milkywaydelivery.com/' class="navbar-brand w-nav-brand">
				  <img
					src={process.env.PUBLIC_URL + '/images/logo-tracking.png'}
					className='logo' alt="Milkyway Delivery Logo" class="mobile" />
			</a>
		   <nav role="navigation" class="nav-menu-wrapper w-nav-menu">
			  <ul role="list" class="nav-menu-list w-list-unstyled">
				 <li><a href="https://www.milkywaydelivery.com/shopify-app-pricing" class="nav-link">Pricing</a></li>
				 <li><a href="https://www.milkywaydelivery.com/contact" class="nav-link">Contact</a></li>
				 <li><a href="https://www.milkywaydelivery.com/faq" aria-current="page" class="nav-link w--current">FAQ</a></li>
				 <li><a href="https://www.milkywaydelivery.com/how-to-use-milkyway-delivery" class="nav-link">App Guide</a></li>
			  </ul>
			  <ul role="list" class="nav-menu-list column-gap-25 w-list-unstyled">
				 <li><a href="https://apps.shopify.com/milkyway-delivery" target="_blank" class="nav-link">Get Started</a></li>
				 <li><a href="https://meetings.hubspot.com/milkyway-delivery" target="_blank" class="primary-button small w-button">Schedule Demo</a></li>
			  </ul>
		   </nav>
		   <div class={`menu-button w-nav-button ${!this.state.isActive ? 'menu-div-hidden' : 'active-div'}`} aria-label="menu" role="button" tabindex="0" aria-controls="w-nav-overlay-0" aria-haspopup="menu" aria-expanded="false" onClick={this.toggleClassactive}>
			  <div class="w-icon-nav-menu">
				  <span></span>
				  <span></span>
				  <span></span>
			  </div>
		   </div>
		</div>
		 {this.state.isActive && ( 
		<div class="w-nav-overlay " id="w-nav-overlay-0" >
		   <nav role="navigation" class="nav-menu-wrapper w-nav-menu">
			  <ul role="list" class="nav-menu-list w-list-unstyled">
				 <li><a href="https://www.milkywaydelivery.com/shopify-app-pricing" class="nav-link">Pricing</a></li>
				 <li><a href="https://www.milkywaydelivery.com/contact" class="nav-link">Contact</a></li>
				 <li><a href="https://www.milkywaydelivery.com/faq" aria-current="page" class="nav-link w--current">FAQ</a></li>
				 <li><a href="https://www.milkywaydelivery.com/how-to-use-milkyway-delivery" class="nav-link">App Guide</a></li>
			  </ul>
			  <ul role="list" class="nav-menu-list column-gap-25 w-list-unstyled">
				 <li><a href="https://apps.shopify.com/milkyway-delivery" target="_blank" class="nav-link">Get Started</a></li>
				 <li><a href="https://meetings.hubspot.com/milkyway-delivery" target="_blank" class="primary-button small w-button">Schedule Demo</a></li>
			  </ul>
		   </nav>
		</div>
		 )}
		</header>
	

    );
  }
}

export default Navbar;
