import dayjs from "dayjs";
import relativeTime from 'dayjs/plugin/relativeTime';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(relativeTime);
dayjs.extend(utc);
dayjs.extend(timezone);

export const stripePK = process.env.REACT_APP_STRIPE_PK;
// export const MILKYWAY_FEE = 3.99; make sure to change to cents in backend env file
export const MILKYWAY_FEE = 0;

export const loadingGif = (
    <img
        className='loading'
        src={process.env.PUBLIC_URL + '/images/loading-gif.svg'}></img>
);

export const Status = {
    SUCCESS: 0,
    GENERAL_ERROR: 1,
    INTERNAL_ERROR: 2,
};

const requiredFields = {
    'pickupAddress': 'Pickup Address',
    'dropoffAddress': 'Dropoff Address',
    'dropoffPhoneNumber': 'Phone Number',
    'dropoffFirstName': 'First Name',
    'dropoffLastName': 'Last Name',
    'packageDeliveryMode': 'Delivery Time',
    'packageValue': 'Package Value',
    'pickupInstructions': 'Pickup Instructions',
    'dropoffInstructions': 'Dropoff Instructions',
};

export const checkMissingFields = (deliveryInfo) => {
    if (deliveryInfo['packageDeliveryMode'] === 'scheduled' &&
        (deliveryInfo['pickupTime'] === undefined ||
            deliveryInfo['dropoffTime'] === undefined)) {
        return 'Pickup and Dropoff times required to schedule delivery';
    }

    for (const [field, displayName] of Object.entries(requiredFields)) {
        if (!deliveryInfo[field]) {
            return displayName;
        }
    }

    return null;
}

export const getPriceInDollars = (quote) => {
    return quote / 100;
}

export const formatDisplayTime = (quote) => {
    const currentTz = dayjs.tz.guess();
    const eta = dayjs(quote.dropoffEta).tz('Greenwich', true).tz(currentTz);
    const result = `ETA ${dayjs(eta).format('h:mm a')}`;

    if (result.includes("NaN") || result.includes("Invalid Date")) {
        return "No ETA";
    }

    // return `${dayjs().to(eta, true)}, ${dayjs(eta).format('h:mm a')}`;
    return result;
}

export const formatETA = (isoString) => {
    const currentTz = dayjs.tz.guess();
    const currentTime = dayjs().tz(currentTz);
    const eta = dayjs(isoString).tz('Greenwich', true).tz(currentTz);
    const result = `${dayjs().to(eta, true)}${currentTime > eta ? ' ago' : ''}, ${dayjs(eta).format('hh:mm a')}`;

    if (result.includes("NaN") || result.includes("Invalid Date")) {
        return "Not Provided By Driver";
    }

    return result;
}

export const formatCreatedAt = (isoString) => {
    const currentTz = dayjs.tz.guess();
    const currentTime = dayjs().tz(currentTz);
    const eta = dayjs(isoString).tz('Greenwich', true).tz(currentTz);
    return `${dayjs(eta).format('DD MMMM YYYY, hh:mm a')}`;
}

export const formatSnakeCase = (s) => {
    return s ? s.split('_').map(word => word[0].toUpperCase() + word.slice(1)).join(' ') : '';
}

export const formatStatusFields = (result) => {
    let res = result;
    res.status = result.status.toLowerCase() === 'not_assigned_driver' ? 'assigning_driver' : result.status;
    for (let i = 0; i < res.statusHistory.length; i++) {
        const status = res.statusHistory[i].status;
        if (status.toLowerCase() === 'not_assigned_driver') {
            res.statusHistory[i].status = 'assigning_driver';
        }
    }

    return res;
}

export const processQuotes = (initialQuotes) => {
    let seen = {};
    for (const quote of initialQuotes) {
        const price = quote.priceCents;
        if (!(price in seen))
            seen[price] = quote;
        else 
            seen[price] = quote.providerId.search(process.env.REACT_APP_QUOTE_KEYWORD) > 0 ? quote : seen[price];
    }

    return Object.keys(seen).reduce((acc, cur) => [...acc, seen[cur]], []);
}

export const metersToMiles = (meters) => {
	const miles = meters * 0.000621371;
	return Math.floor(miles * 100) / 100; 	// nearest 10th
}

export const StatusText = (props) => {
    let status = props.status;
    if (status === undefined)
        return;

    let category = (status === Status.SUCCESS) ? 'success-text' : 'error-text';
    return <p className={category}>{props.statusMessage}</p>;
}

export const MapLoading = (status) => {
    return <h1>{status}</h1>;
}
