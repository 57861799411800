import React, { useState, useEffect, useRef } from 'react';
import mapStyle from './MapStyle';

const width = window.innerWidth;
const height = window.innerHeight;

let geocoder;
let directionsService;
let directionsRenderer;

const getRootElementFontSize = () => {
    return parseFloat(
        getComputedStyle(
            document.documentElement
        ).fontSize
    );
}

const Marker = (options) => {
    const [marker, setMarker] = useState();

    useEffect(() => {
        if (!marker) {
            setMarker(new window.google.maps.Marker({
                icon: {
                    url: process.env.PUBLIC_URL + '/images/car-icon.png',
                    scaledSize: new window.google.maps.Size(35, 35),
                },
            }));
        }

        // remove marker from map on unmount
        return () => {
            if (marker) {
                marker.setMap(null);
            }
        };
    }, [marker]);

    useEffect(() => {
        if (marker) {
            marker.setOptions(options);
        }
    }, [marker, options]);

    return null;
};

const Map = ({ onClick, onIdle, children, style, pickup, dropoff, ...options }) => {
    const ref = useRef();
    const directionPoints = useRef({
        pickup: undefined,
        dropoff: undefined,
    });
    const [map, setMap] = useState();

    const initDirections = () => {
        if (!directionsService && window.google) {
            directionsService = new window.google.maps.DirectionsService();
        }
        if (!directionsRenderer && window.google) {
            directionsRenderer = new window.google.maps.DirectionsRenderer();
        }

        return directionsService && directionsRenderer;
    };

    // add map hook
    useEffect(() => {
        if (ref.current && !map) {
            setMap(new window.google.maps.Map(ref.current, { styles: mapStyle }));
        }
    }, [ref, map]);

    useEffect(() => {
        initDirections();

        if (!map || !pickup || !dropoff)
            return;

        console.log('Geocoding');

        // Make sure we don't make excessive requests to directions api
        if (pickup === directionPoints.current.pickup &&
            dropoff === directionPoints.current.dropoff)
            return;

        if (pickup != directionPoints.current.pickup)
            directionPoints.current.pickup = pickup;

        if (dropoff != directionPoints.current.dropoff)
            directionPoints.current.dropoff = dropoff;

        // Make directions request
        const request = {
            origin: directionPoints.current.pickup,
            destination: directionPoints.current.dropoff,
            travelMode: 'DRIVING',
        };

        directionsRenderer.setMap(map);
        directionsService.route(request, (result, status) => {
            if (status === 'OK') {
                directionsRenderer.setDirections(result);
            }
        });
    }, [pickup, dropoff, map]);

    return (
        <>
            <div ref={ref} style={style} className="map-wrapper"/>
            {React.Children.map(children, (child) => {
                return React.cloneElement(child, { map });
            })}
        </>
    );
}

const DeliveryMap = (props) => {
    return (
        <Map
            center={{
                lat: 34.0552,
                lng: -118.2437,
            }}
            zoom={11}
            pickup={props.pickupAddress}
            dropoff={props.dropoffAddress}
            driverLocation={props.driverLocation}
            style={{ width: '736px', height: '765px'}}
        >
            {props.driverLocation && (
                <Marker
                    position={new window.google.maps.LatLng(props.driverLocation)}
                    title="driver"
                />
            )}
        </Map>
    );
}

export default DeliveryMap;