import React, {useState, useEffect} from 'react';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import AutocompleteInput from './PlacesAutocomplete';
import { Wrapper, Status } from '@googlemaps/react-wrapper';
import { OrderConfirmation } from "./ConfirmationPage";

/* global google */

import StripeWrapper from './Stripe';
import {MILKYWAY_FEE, metersToMiles, StatusText} from './utils';

const TextBox = (props) => {
    const [value, setValue] = useState('');

    const handleChange = (e) => {
        setValue(e.target.value);
        props.updateField(props.field, e.target.value);
    };

    return (
        <TextField
            className='text-box'
            label={props.label}
            onChange={props.onChange || handleChange}
            value={value}
            required={props.required}
            size='small'
            multiline={props.multiline || false}
            placeholder={props.multiline && props.placeholder}
            rows={3}
        />
    );
};

const DeliveryRequestForm = (props) => {
    const changeLocation = (field, value, options) => {
        if (options) {
            const location = options.find(option => option.description === value);
            const placeIdField = field + 'PlaceId';
            if (location !== undefined) {
                props.updateField(placeIdField, location.place_id);
            } else {
                if (props[placeIdField] !== undefined)
                    props.updateField(placeIdField, undefined);
            }
        }

        // Set address field
        props.updateField(field, value);
    }

    return (
        <div className='input-form delivery-request-form'>
            <p className='form-title'>Delivery Request</p>
            <Box
                sx={{
                    '& >:not(style)': {mt: 1, mb: 1}
                }}
            >
                <Wrapper apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY} libraries={['places, directions']}>
                    <AutocompleteInput
                        changeLocation={changeLocation}
                        label='Pickup address'
                        field='pickupAddress'
                    />
                    <AutocompleteInput
                        changeLocation={changeLocation}
                        label='Dropoff address'
                        field='dropoffAddress'
                    />
                </Wrapper>
                {props.distance && <p>Distance: {metersToMiles(props.distance)}</p>}
                <TextBox updateField={props.updateField} label='Full Name' field='dropoffName' required={true}/>
                {/* <TextBox updateField={props.updateField} placeholder='First Name' field='dropoffFirstName' required={true} />
                <TextBox updateField={props.updateField} placeholder='Last Name' field='dropoffLastName' required={true} /> */}
                <TextBox updateField={props.updateField} label='Phone number' field='dropoffPhoneNumber'
                         required={true}/>
                <TextBox updateField={props.updateField} type='number' label='Package value USD' field='packageValue'
                         required={true}/>
                <TextBox updateField={props.updateField} label='Pickup instructions'
                         placeholder='where/how to pick-up items, pickup store name, etc.' field='pickupInstructions'
                         required={true} multiline/>
                <TextBox updateField={props.updateField} label='Dropoff instructions'
                         placeholder='where/how to leave items' field='dropoffInstructions' required={true} multiline/>
            </Box>
            {/* <hr className='section-divider'></hr> */}
        </div>
    );
}

const PackageForm = (props) => {
    const [deliveryMode, setDeliveryMode] = useState('now');
    const [packagePreviewLink, setPackagePreviewLink] = useState(undefined);

    const updatePreview = (e) => {
        const packagePreview = e.target.files[0];
        if (!packagePreview) {
            setPackagePreviewLink(undefined);
            return;
        }
        const fileLink = URL.createObjectURL(packagePreview);
        setPackagePreviewLink(fileLink);
    };

    const handleChange = (e, value) => {
        if (!value)
            return;

        setDeliveryMode(value);
        props.updateField('packageDeliveryMode', value);
    };

    return (
        <div className='input-form package-form'>
            <div className='package-info'>
                <Box
                    sx={{
                        '& >:not(style)': {mt: 1, mb: 1}
                    }}
                >
                    {/* <p className='form-title'>Package</p>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <p>Pickup Time</p>
            <ToggleButtonGroup onChange={handleChange} exclusive={true} value={deliveryMode} size='small'>
              <ToggleButton value='now' disableRipple={true} variant="contained">Now</ToggleButton>
              <ToggleButton value='scheduled' disableRipple={true} variant="contained">Schedule</ToggleButton>
            </ToggleButtonGroup>
          </div>
          {(deliveryMode === 'scheduled') && (
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <TimePicker
                label='Pickup Time'
                value={props.pickupTime}
                onChange={(value) => props.updateField('pickupTime', value)}
                renderInput={(params) => <TextField {...params} />}
              />
              <TimePicker
                label='Dropoff Time'
                value={props.dropoffTime}
                onChange={(value) => props.updateField('dropoffTime', value)}
                renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider>
          )} */}
                    {/* <TextBox updateField={props.updateField} type='number' placeholder='How many items?' field='numItems' required={false} /> */}
                    {/* <TextBox updateField={props.updateField} type='number' placeholder='Package value USD' field='packageValue' required={true} /> */}
                </Box>
                <p className='section-info'>Your package is covered up to $2,000 for free with Milkyway Protection
                    Policy.</p>
            </div>
            {/* <div className='package-image'>
                <label htmlFor='package-image'>Add package image (optional)</label>
                <input id='package-image' type='file' accept='image/png, image/jpeg' onChange={updatePreview} />
                {packagePreviewLink && <img className='package-preview' src={packagePreviewLink} />}
            </div> */}
            <hr className='section-divider'></hr>
        </div>
    );
}

const PaymentForm = (props) => {
    const getTotalDisplayPrice = () => {
        let deliveryPrice = undefined;

        if (props.promoCode !== undefined) {
            deliveryPrice = 0;
        } else if (props.requestedPrice !== undefined) {
            deliveryPrice = props.requestedPrice + MILKYWAY_FEE;
        } else if (props.quoteIndex >= 0 && props.promoCode === undefined) {
            deliveryPrice = props.jobInfo.quotes[props.quoteIndex].totalPrice;
        } else {
            return undefined;
        }

        return deliveryPrice.toLocaleString("en-US", {style: "currency", currency: "USD"});
    }

    const handleChange = (e, value) => {
        // Prevent user from deselecting a button
        if (value === null)
            return;

        props.updateField('quoteIndex', value);
        props.updateField('requestedPrice', undefined);
        props.updateField('promoCode', undefined);
    };

    const handleCustomChange = (e, value) => {
        if (isNaN(Number(value)) || value.trim().length === 0)
            value = undefined;
        else
            value = Number(value);

        props.updateField('quoteIndex', -1);
        props.updateField('requestedPrice', value);
        props.updateField('promoCode', undefined);
    }

    const isFreeDelivery = (value) => {
        return value.promoCode !== null && value.promoCode !== undefined;
    }

    // Set default price on first mount
    useEffect(() => {
        props.updateField('quoteIndex', -1);
    }, []);

    const cost = getTotalDisplayPrice();

    return (
        <div className='input-form payment-form'>
            {!props.stripeClientSecret && (<div className='payment-info'>
                <p className='form-title'>Payment</p>
                <div className='price-selection'>
                    <Box
                        sx={{
                            '& >:not(style)': { mt: 1 }
                        }}
                    >
                        <TextBox updateField={handleCustomChange} type='number' value={props.requestedPrice}
                                 label='How much do you offer to driver?' field='requestedPrice'/>
                        <TextBox updateField={props.updateField} type='text' value={props.promoCode}
                                 label='Free Delivery Promo Code' field='promoCode'/>
                    </Box>
                    <p>Or choose one of these options</p>

                    <ToggleButtonGroup onChange={handleChange} exclusive={true} value={props.quoteIndex}
                                       orientation='vertical' aria-label='vertical outlined button group'>
                        {props.jobInfo.quotes.map((quote, i) => {
                            return (
                                <ToggleButton
                                    key={i}
                                    value={i}
                                    disableRipple={true}
                                    variant='contained'
                                >
                                    <p>{quote.price.toLocaleString('en-US', {style: 'currency', currency: 'USD'})}<br/>
                                        {quote.eta}</p>
                                </ToggleButton>
                            )
                        })}
                    </ToggleButtonGroup>
                </div>
                {cost && (
                    <>
                        {/* <p>Milkyway fee: {isFreeDelivery(props) ? cost : props.milkywayFee}</p> */}
                        <p>Milkyway fee: <strike>$3.99</strike> $0</p>
                        <p className='total-text'>Total: {cost}</p>
                    </>
                )}
            </div>)}
            <div className='payment-method'>
                {props.stripeClientSecret && (
                    <>
                        {isFreeDelivery(props) ?
                            <></> :
                            <p className='form-title'>Checkout</p>
                        }
                        {/*{paymentPage(props) }*/}
                        {isFreeDelivery(props) ?
                            <OrderConfirmation trackingLink={`/delivery-tracking?jobId=${props.jobInfo.id}`}/> :
                            <StripeWrapper stripeClientSecret={props.stripeClientSecret} jobInfo={props.jobInfo}/>
                        }
                    </>
                )}
            </div>
            <hr className='section-divider'></hr>
        </div>
    );
}

const SubmissionBox = (props) => {
    return props.showButton && (
        <div className='input-form-col'>
            {/* <button className='submit-button' onClick={props.requestDelivery}>Request</button> */}
            <Button variant='contained' onClick={props.onSubmit}>{props.buttonMessage}</Button>
            <StatusText
                status={props.status}
                statusMessage={props.statusMessage}/>
        </div>
    );
}

const Sidebar = (props) => {
    return (
        <Box
            id='sidebar'
        >
            {!props.jobInfo && (
                <>
                    <DeliveryRequestForm
                        updateField={props.updateField}
                        pickupAddressPlaceId={props.pickupAddressPlaceId}
                        dropoffAddressPlaceId={props.dropoffAddressPlaceId}
                        distance={props.distance || ''}
                    />
                    <PackageForm
                        updateField={props.updateField}
                        pickupTime={props.pickupTime}
                        dropoffTime={props.dropoffTime}
                    />
                </>)}
            {props.jobInfo && (
                <PaymentForm
                    updateField={props.updateField}
                    quoteIndex={props.quoteIndex}
                    promoCode={props.promoCode}
                    requestedPrice={props.requestedPrice}
                    milkywayFee={props.milkywayFee}
                    stripeClientSecret={props.stripeClientSecret}
                    jobInfo={props.jobInfo}/>
            )}
            <SubmissionBox
                showButton={props.stripeClientSecret === undefined}
                // buttonMessage={props.promoCode === undefined || props.promoCode === null ? 'Next' : 'Request Delivery'}
                buttonMessage={'Next'}
                onSubmit={props.jobInfo ? props.requestDelivery : props.getQuotes}
                status={props.status}
                statusMessage={props.statusMessage}
            />
        </Box>);
}

export default Sidebar;